import axios from "axios";
import { baseURL } from "../utils/constants";
const Api = axios.create({
  baseURL: baseURL,
});

Api.interceptors.response.use(
  (response) => {
    if (response.status == 200) {
      if (response.data == "Token Expired") {
        localStorage.removeItem("user");
        localStorage.removeItem("authToken");
        localStorage.removeItem("token");
        localStorage.removeItem("ad_list");
        localStorage.removeItem("ad_price");
        //window.location.href = "/login";
      }
    }
    return response;
  },
  (err) => {
    if (err?.message == "Network Error") {

    }
    else if (err?.response?.status === 500) {
      return { data: [], status: true, error: "Something went wrong" }
    } else {
      localStorage.removeItem("user");
      localStorage.removeItem("authToken");
      localStorage.removeItem("token");
      localStorage.removeItem("ad_list");
      localStorage.removeItem("ad_price");
      //return (window.location.href = "/login");
    }

  }
);

export const registerUserApi = (data) => Api.post(`users/add`, data);

export const loginUserApi = (data) => Api.post(`users/login`, data);

export const validateTokenApi = (token) =>
  Api.get(`users/check-token?api_token=${token}`);

export const LogoutUserApi = (token) =>
  Api.post(`users/logout?api_token=${token}`);

export const forgotPwdApi = (data) =>
  Api.post(`email-reset-code`, data);
  
export const verificationCodeApi = (data) =>
  Api.post(`validate-code`, data);

export const resetPasswordApi = (data) =>
  Api.post(`resetPassword`, data);

export const readNotification = (token, data) =>
  Api.post(`users/readnotification?api_token=${token}`, data); 

export const publisherDashboardApi = (token) =>
  Api.get(`publisher/dashboard?api_token=${token}`);

export const notificationApi = (token) =>
  Api.get(`users/getNotification?api_token=${token}`);

export const publisherAdsApi = (token) =>
  Api.get(`publisher/ads?api_token=${token}`);

export const publisherAdApi = (token, id) =>
  Api.get(`publisher/ad/${id}?api_token=${token}`);

export const SolvedAdApi = (token, id) =>
  Api.get(`publisher/adSolved/${id}?api_token=${token}`);

export const demographicAdApi = (token, id) =>
  Api.get(`publisher/adDemographic/${id}?api_token=${token}`);

export const visitsAdApi = (token, id) =>
  Api.get(`publisher/adVisits/${id}?api_token=${token}`);

export const socialVisits = (token, id) =>
Api.get(`publisher/socialVisits/${id}?api_token=${token}`);

//get all countries
export const countriesApi = (lang) => Api.get(`country/list/${lang}`);

//get state by country id
export const getStateByCountryId = (id,lang) => 
Api.get(`city/getByCountryId/${id}/${lang}`);

//get city by state id
export const getCitiesByStateId = (id,lang) => 
Api.get(`user/getNeighborhoodByState/${id}/${lang}`)

//get all states
export const statesApi = (lang) => Api.get(`city/list/${lang}`);

//get all cities
export const citiesApi = (lang) => Api.get(`neighborhood/list/${lang}`);

export const subscribtionsApi = (lang) => Api.get(`subscription/${lang}`);

export const subscriptionByIdApi = (id) => Api.get(`publisher/getSubscriptionById/${id}`);

export const createCompanyApi = (token, data) =>
  Api.post(`company/add?api_token=${token}`, data);

export const createAdApi = (token, data,setProgress) =>
  Api.post(`Ads/add?api_token=${token}`, data,{
    onUploadProgress:(event)=>{
      const { loaded,total } = event;
      const percentage = Math.floor((loaded * 100) / total);
      setProgress(percentage)
    }
  }).then((res) => res);

export const updateAdApi = (token, data, id) =>
  Api.post(`Ads/update/${id}?api_token=${token}`, data);

export const saveAudienceApi = (token, data) =>
  Api.post(`Ads/save-audience?api_token=${token}`, data);

export const createQuestionApi = (token, data) =>
  Api.post(`question/add?api_token=${token}`, data);

export const commentsApi = (productId, lang) =>
  Api.get(`comment/${productId}/${lang}`);

export const addCommentApi = (token, data) =>
  Api.post(`comment/add?api_token=${token}`, data);

export const replyCommentApi = (token, commentId, data) =>
  Api.post(`comment/reply/${commentId}?api_token=${token}`, data);

export const updateProfileApi = (token, data) =>
  Api.post(`user/setData?api_token=${token}`, data);

export const getSubscriptionByAd = (token, id) =>
  Api.get(`publisher/getSubscription/${id}?api_token=${token}`);

export const submitSubscriptionProof = (token, data) =>
  Api.post(`publisher/product_subscribe?api_token=${token}`, data);

export const renewAd = (token, data, id) =>
  Api.patch(`Ads/renew/${id}?api_token=${token}`, data);

export const isRenewable = (token, id) =>
  Api.get(`Ads/isAdRenewable/${id}?api_token=${token}`);

export const getProductQuestionsById = (token, id) =>
  Api.get(`publisher/product/questions/${id}?api_token=${token}`);

export const readAllNotification = (token) =>
  Api.post(`users/readallnotification?api_token=${token}`);

export const removeAllNotification = (token) =>
  Api.post(`users/removeallnotification?api_token=${token}`);
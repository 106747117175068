import { createTheme } from '@material-ui/core/styles';

export default createTheme({
  typography: {
    fontFamily: `"avenir next medium", "Open Sans", "Helvetica", "Arial", sans-serif`
  },
  overrides: {
    MuiTypography: {
      root: {
        wordBreak: "break-word",
      }
    },
    MuiCard:{
      root:{
        padding:"10px",
        margin:"0.5rem 0"
      }
    },
    MuiCardHeader:{
      root:{
        position:"relative"
      }
    },
    MuiButton:{
      label:{
        color:"#ffffff"
      }
    }
  },
  palette:{
    primary:{
      main:"#263d4b"
    },
    secondary:{
      main:"#55bc72"
    }
  }
});
export const en = {
  getStarted: 'Get Started',
  english: 'English',
  arabic: 'Arabic',
  asUser: 'Sign in as user',
  asPublisher: 'Sign in as publisher',
  earner: 'Earner',
  publisher: 'Publisher',
  login: 'Login',
  loginTitle: 'Welcome back',
  forgotPwd: 'Forgot password?',
  newAccount: "Don't have an account?",
  register: 'Register',
  haveAccount: 'Already have an account?',
  email: 'Email Address',
  emailrequired: 'Email is required',
  invalidemail: 'Email is invalid',
  password: 'Password',
  pwdRequired: 'Password is required',
  password_confirmation: 'Confirm Password',
  confirmPwdRequired: 'Passwords Confirmation is required',
  pwdsNotMatch: "Passwords doesn't match",
  advertister: 'Advertister',
  watcher: 'Watcher',
  fName: 'First Name',
  fNameRequired: 'first name is required',
  lName: 'Last Name',
  lNameRequired: 'last name is required',
  invalidCredentials: 'Invalid Email or Password',
  forgotPwdTitle: "Oh Don't Worry!",
  sendEmail: 'Send Email',
  doneMsg1: 'Email has been sent!',
  doneMsg2: "the verification code has been sent to your email",
  doneMsg3: 'please, check your email inbox...',
  NewPassMsg1: 'Verification code successfully entered!',
  NewPassMsg2: 'please, set your new password below...',
  backToLogin: 'back to login',
  confirmButtonText: 'Got It',
  logout: 'Logout',
  thamin: 'Thamin',
  dashboard: 'Publisher Dashboard',
  watchers: 'Watchers',
  visitors: 'Visitors',
  comments: 'Comments',
  likes: 'Likes',
  views: 'Views',
  wronganswers: 'Not Earned',
  monthlyviews: 'Monthly Views',
  steps: 'Creating Ad Steps',
  postad: 'Create Ad',
  createcompany: 'Create Company',
  dashboard: 'Dashboard',
  myads: 'My Ads',
  profile: 'Profile',
  activeads: 'Active Ads',
  pendingads: 'Pending Ads',
  rejectedads: 'Rejected Ads',
  closedads: 'Closed Ads',
  uncompleteads: 'Uncomplete Ads',
  adname: 'Name',
  adTitle: 'Ad Title',
  adKeywords: 'Ad Keywords',
  arLang: 'ar',
  enLang: 'en',
  adDescription: 'Ad Description',
  category: 'Category',
  remainingviews: 'Remaining Winners',
  action: 'Reports',
  adslist: 'Ads List',
  search: 'Search',
  report: 'Report',
  goback: 'Go Back ',
  questions: 'Questions',
  views: 'Views',
  demographic: 'Demographic',
  solvedquestions: 'Users solved question',
  option: 'Option',
  visitspermonth: 'User visits per month',
  demographicbyagegender: 'Demographic By Age & Gender',
  male: 'Male',
  female: 'Female',
  males: 'Males',
  females: 'Females',
  noCommentsYet: 'No Comments Yet',
  userOpinions: 'Users Opinions',
  usersolvedquestionandearning: 'User Solved Questions & Earning',
  solvedquestions: 'Solved Questions',
  earned: 'Earned',
  createcompany: 'Create Company',
  companyLogo: 'Upload Company Logo',
  upload: 'Upload',
  companyName: 'Company Name',
  incorrectnumber: 'Incorrect Phone Number',
  selectCountry: 'Select a country',
  selectCity: 'Select a city',
  companyAddress: 'Company Address',
  instagram: 'Instagram Account',
  snapchat: 'Snapchat Account',
  whatsapp: 'Whatsapp Number',
  create: 'Create',
  cancel: 'Cancel',
  companyexist: 'You already requested for company!',
  createad: 'Create Ad',
  adcategory: 'Ad Category',
  costCalculation: 'Cost Calculation',
  calcViews: 'Views',
  calcTax: 'Tax',
  calcGift: 'Gift',
  calcEarningUser: 'Earning User',
  audience: 'Audience Details',
  whocanseead: 'Who can see your ad?',
  audiencedetails: 'Audience Details',
  edit: 'Edit',
  gender: 'Gender',
  age: 'Age',
  location: 'Location',
  countries: 'Countries',
  nocountry: 'No Country Selected',
  state: 'States',
  nostate: 'No States Selected',
  noneighborhood: 'No Neighborhood Selected',
  userinterest: 'User Interest',
  countryinterest: 'Country Interest',
  selectuserinterest: 'Select User Interests',
  selectcountryinterest: 'Select Country Interests',
  selectstateinterest: 'Select State Interests',
  selectneighborhoodinterest: "Select Neighborhood Interests",
  stateinterest: 'State Interest',
  nouserinterest: 'No User Interest Selected',
  adcreative: 'Ad Creative',
  media: 'Media',
  image: 'Image',
  video: 'Video',
  poster: 'Poster',
  website: 'Website',
  totalbudget: 'Total Budget',
  budgettooltip:
    'The maximum you will spend on your ad, the more people will reach.',
  estimated: 'Estimated',
  peoplereach: 'people reached per day',
  sar: 'SAR',
  editaudience: 'Edit Audience',
  editaudiencetext:
    'Select the location, age, gender and interests of people you want to reach with your ad',
  cancel: 'Cancel',
  saveaudience: 'Save Audience',
  all: 'All',
  droppin: 'Drop Pin',
  ok: 'Ok',
  personalInfo: 'Personal Info',
  edit: 'Edit',
  editProfile: 'Edit Profile',
  profileName: 'Profile Name',
  selectGender: 'select gender',
  mobile: 'Phone Number',
  birthdate: 'Birthdate',
  changeProfile: 'change Profile',
  changeCover: 'change Cover',
  saveChanges: 'Save Changes',
  companyInfo: 'Company Info',
  payment: {
    formTitle: 'Payment Process',
    name: 'Card Name',
    number: 'Card Number',
    cvc: 'CVC code',
    payBtn: 'Send',
  },
  reply: 'Reply',
  post: 'post',
  typeReply: 'Type your reply here...',
  typeComment: 'Type your opinion here...',
  publishCheck: 'Unpublish',
  replyCheck: 'hide reply',
  selectAdCategory: 'Select Ad Category',
  chooseAdMedia: 'Choose Ad Media',
  chooseAdPoster: 'Choose Ad Poster',
  additionalInfo: 'Additional Info',
  requiredCategory: 'Category field is required',
  requiredWebsite: 'Please enter correct url format',
  requiredTitle: 'Title field is required',
  requiredDes: 'Description field is required',
  desLimit: 'Description Field must be at least 20 chars',
  desMaxLimit: 'Description Field cannot have more then 200 chars',
  titleLimit: 'Title Field must be at least 5 chars',
  titleLimit1: 'Title Field must be more then 5 chars',
  titleMaxLimit1: 'Title Field must be less then 20 chars',
  titleMaxLimit: 'Title Field cannot have more then 25 chars',
  titleLimit2: 'Title Field must be more then 20 chars',
  titleMaxLimit2: 'Title Field cannot have more then 50 chars',
  requiredMedia: 'Media field is required',
  requiredPoster: 'Poster field is required',
  requiredKeywords: 'keywords field is required',
  enterGiftAmount: 'Enter how much gift cost or discount',
  giftAmountLabel: 'Gift Amount',
  gift: 'Gift',
  discount: 'Discount',
  nothing: 'Nothing',
  selectGift: 'select a gift',
  giftType: 'Gift Type',
  grossTotal: 'Gross Total',
  up: 'Up',
  whatGiftType: 'What you will give users who share the Ad?',
  one:'01',
  two:'02',
  three:'03',
  four:'04',
  step1: 'Step 1',
  step1Title:'Open a verified advertiser account',
  step2: 'Step 2',
  step2Title: 'Create a new ad',
  step3: 'Step 3',
  step3Title: 'Fill in all the ad data and upload the ad as a video or image with the main image as the ad interface',
  step4: 'Step 4',
  step4Title: 'Choose the appropriate package for your business, then attach a copy of the payment proof',
  yes:"Yes",
  no:"No",
  addQuestion:"Add Question",
  typeQuestion:"Enter your ad quiz question",
  typeAnswers:"Enter at least two answers",
  typeAnswer:"Enter your answer here",
  addMoreAnswer:"Add More Answer +",
  submit:"Submit",
  twoAnswers:"Answers must be two or more",
  paymentMethods:"Payment Methods",
  creditDebitPayment:"Credit/Debit Card Payment",
  bankPayment:"Bank Payment",
  creditDebitPay:"Using this method, You can pay from your debit or credit card",
  bankPay:"Using this method, You need to submit bank receipt here to verify",
  continue:"Continue",
  allNotifications:"All Notifications",
  viewAll:"View All",
  markAll:"Mark all as read",
  paymentSuccess:"Payment has been recieved successfully",
  visitAd:"Visit Ad",
  charactor5to10:"Characters should be between 5 to 20",
  charactor20to50:"Characters should be between 20 to 50",
  videoImageSize:"Video and image size should not be more then 6mb for image and 10 mb for video",
  supportedFormats:"Supported Formats: .mp4, .jpg, .png, .jpeg",
  name :'Username',
  country:'Country',
  neighborhood:'Neighborhood',
  shares : 'Visits by Shared Links',
  winners:"Winners",
  winners_des:"Correctly solved by these users",
  visits : 'Visits by users',
  visits_des : 'These users tried to solve the questions',
  adPreview:"Ad Preview",
  performanceOverview:"Performance Overview",
  rowsPerPage:"Rows Per Page",
  download:"Download",
  thankyou:"Thank you",
  newadrequest:"A new ad request has been successfully submitted",
  adreview:"Your ad will be reviewed and in the event of any comments or violations of the terms of the site, we will send you a notice to amend within 24 hours",
  slogan:"With precious your ad is precious",
  selectAll:"Select All",
  renew:"Renew",
  continueToPayment:"Continue To Payment",
  numberOfUsers:"Number of user you want to give gifts",
  thminMysteryBox:"Thamin Mystery box",
  notSet:"Not Set",
  editAudienceDescription:"Select audience from this panel",
  noCommentYet:"No Comments Added Yet",
  subscription_proof:"Subscription Proof",
  amountToPay:"Amount to pay",
  ryal:"Rayal",
  paymentDetails:"Payment Details",
  bankDetails:"Bank Details",
  forBankPayment:"For Bank Payment",
  companyCreated:"Company Created Successfully",
  bankTax:"Tax Bank",
  adPrice:"Ad Price",
  adOrder:"Your ad order ",
  adOrderRecieved:" has been received ",
  congratulations:"Congratulations !",
  adPublishedRecieved:"is published now for the users",
  comment:"New comment in your ad",
  social:"Social Clicks",
  social_desc:"These people visit social media account",
  account:"Account",
  accountInfo: "User Information",
  analytics: "Analytics",
  chooseAdSubPoster: "Add advertising information that expresses and explains your services or products.",
  chooseVidOrImg: "Add a video or photo of your services and products",
  fullName: "Full Name",
  birthday: "Birth date",
  gender: "Gender",
  country: "Country",
  email: "Email",
  mobile: "Mobile",
  address: "Address",
  nationality: "Nationality",
  language: "Language",
  socialMedia: "Social Media",
  date: "date",
  passwordRest: "password has been successfully reset!",
  labelPassword: "please enter your verification code",
  verificationCode: "verification code",
  labelNewPass: "please enter your new password",
  labelReNewPass: "please re-enter your new password",
  resetPass: "Reset Password",
  statee: "State",
  neighborhood: "Neighborhood",
  removeAll: "remove all",
  quesRequired: "Question required",
  ansRequired :"Answers required! you should enter at least two answers ",
  neighborhoodinterest: "Neighborhood Interest",
  chooseSubAdPoster: "It is a teaser image called the landing page of the advertising campaign",
  resPass: "Reset Password",
  addQuesExp: "choose the right answer by clicking",
  fileSize: "File Size",
  Mb: "mb",
  selectVideoMethod: "Select Video Method",
  or: "Or",
  uploadVideo: "Press to upload video",
  pasteUrl:"Paste a video url",
  allowedPaste:"You can only paste the url",
  invalidUrl:"URL is not valid",
  youtubeNotAllowed:"Youtube url is not allowed",
  countryLimit:"You cannot select more than one country",
  stateLimit:"You cannot select more than two cities", 
}
